import React from "react";
import {
  ArrayInput,
  Edit,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import IconInput from "../../inputs/IconInput";

const configureQuill = (quill) =>
  quill.getModule("toolbar").addHandler("bold", function (value) {
    this.quill.format("bold", value);
  });

// IconInput popover shows outside the form.
const theme = createMuiTheme({
  overrides: {
    RaEdit: {
      overflow: "visible",
    },
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
  },
});

export default (props) => (
  <ThemeProvider theme={theme}>
    <Edit {...props} style={{ overflow: "visible" }}>
      <SimpleForm style={{ overflow: "visible" }}>
        <TextInput source="websiteTitle" validate={required()} />
        <RichTextInput
          source="footerContent"
          toolbar={[[{ header: [4, false] }]]}
          configureQuill={configureQuill}
        />
        <ArrayInput source="contactNumbers">
          <SimpleFormIterator>
            <TextInput source="name" validate={required()} />
            <TextInput source="number" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="socials">
          <SimpleFormIterator>
            <IconInput
              source="icon"
              initialValue="FaQuestion"
              validate={required()}
            />
            <TextInput source="url" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  </ThemeProvider>
);
