import * as React from "react";
import { useSelector } from "react-redux";
import {
  DashboardMenuItem,
  MenuItemLink,
  getResources,
  useTranslate,
} from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";

export default () => {
  const translate = useTranslate();
  const resources = useSelector(getResources);

  return (
    <div>
      <DashboardMenuItem />
      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={resource.hasList ? `/${resource.name}` : `/${resource.name}/edit`}
          primaryText={translate(`resources.${resource.name}.name`)}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        />
      ))}
      {/* add your custom menus here */}
    </div>
  );
};
