import * as React from 'react';
import {
    Edit,
    CreateProps,
    ReferenceInput,
    SimpleForm,
    TextInput,
    SelectInput, ImageField,
    required, ImageInput, useRecordContext,
} from 'react-admin';
import { Box, CardContent, Divider, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';

const PreviewImage = ({ record, source }) => {
    if (typeof (record) === "string") {
        record = {
            [source]:record
        }
    }

    return <ImageField record={record} source={source} />
}

export const SponsorEdit = (props) => {
    return (
        <Edit {...props} actions={false}>
            <SimpleForm component={CustomLayout} redirect="show">
                <ImageInput source="image">
                    <PreviewImage source="src" />
                </ImageInput>
                <SelectInput source="sponsorType" choices={[
                    { id: 'commander', name: 'Bổ trợ chỉ đạo' },
                    { id: 'executor', name: 'Tổ chức thực hiện' },
                    { id: 'sponsor', name: 'Đơn vị tài trợ' },
                ]} />
                <TextInput source="name" validate={required()} fullWidth />
                <CustomDivider />
            </SimpleForm>
        </Edit>
    );
};

const CustomLayout = (props) => (
    <CardContent>
        <Box display="flex">
            <Box paddingTop={1}>
                <Avatar>
                    <BusinessIcon />
                </Avatar>
            </Box>
            <Box ml={2} flex="1" maxWidth={796}>
                {props.children}
            </Box>
        </Box>
    </CardContent>
);

const CustomDivider = () => (
    <Box mb={2}>
        <Divider />
    </Box>
);
