import * as React from "react";
import { List, Datagrid, TextField } from "react-admin";

export const CompetitionList = (props) => (
  <List {...props} sort={{ field: "year", order: "DESC" }}>
    <Datagrid rowClick="edit">
      <TextField source="year" />
    </Datagrid>
  </List>
);
