import * as React from "react";
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
    useListContext,
    CreateButton,
    List, Datagrid, TextField, DateField,useTranslate, ListActions, useRecordContext,
} from 'react-admin';

const Empty = () => {
    const { basePath, } = useListContext();
    const translate = useTranslate(); // returns the i18nProvider.translate() method
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                {translate('resources.organization-chart.fields.noOrganization')}
            </Typography>
            <CreateButton basePath={basePath} />
        </Box>
    );
};

const TranslateTextField = (props) => {
    const translate = useTranslate();
    const { source } = props;
    const record = useRecordContext(props);

    return<Chip label={translate('resources.organization-chart.fields.'+record[source])} />;
}

TranslateTextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export const OrganizationList = (props) => (
    <List {...props} actions={<ListActions/>} empty={<Empty />}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <TextField source="title1"/>
            <TextField source="title2"/>
            <TextField source="title3"/>
            <TranslateTextField source="userType"/>
            <DateField source="created_at"/>
        </Datagrid>
    </List>

);
