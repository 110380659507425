import * as React from "react";
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
    useListContext,
    CreateButton,
    List, Datagrid, TextField, DateField,useTranslate, ListActions, useRecordContext,ImageField
} from 'react-admin';

const Empty = () => {
    const { basePath, } = useListContext();
    const translate = useTranslate(); // returns the i18nProvider.translate() method
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                {translate('resources.sponsors.fields.noSponsor')}
            </Typography>
            <CreateButton basePath={basePath} />
        </Box>
    );
};

export const CategoryList = (props) => {

    return (
        <List {...props} actions={<ListActions/>} empty={<Empty/>}>
            <Datagrid rowClick="edit">
                <TextField source="name"/>
                <TextField source="note"/>
                <DateField source="created_at"/>
            </Datagrid>
        </List>

    );
}
