import axios from "axios";
import axiosRetry from "axios-retry";
import { set, get, has } from "lodash";
import getObjectPaths from "../util/getObjectPaths";

export const uploadFile = async (file, options = {}) => {
  //if change this, please change joditConfig.js as well
  const data = new FormData();
  data.append("file", file);

  // In some cases server will crash after image upload.
  // We should try again in order to send request to new/alternate api server.
  axiosRetry(axios, {
    retries: options.retries,
    retryDelay: axiosRetry.exponentialDelay,
    shouldResetTimeout: false,
  });

  return await axios.post(`${options.apiUrl}/upload`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    timeout: options.timeout,
  });
};

const processFiles = () => async (context) => {
  const paths = getObjectPaths(context.data);

  // Only upload one image at a time to prevent overloading the server.
  for (const path of paths) {
    const rawFile = get(context.data, `${path}.rawFile`);

    if (!rawFile || !(rawFile instanceof File)) continue;

    const options = {
      apiUrl: process.env.REACT_APP_API_URL,
      timeout: 300000, // 5min
      retries: 2,
    };

    try {
      const { data } = await uploadFile(rawFile, options);
      if (has(data, "uri")) delete data.uri;

      if (rawFile.type === "application/pdf") {
        data.title = rawFile.name;
        data.url = `${process.env.REACT_APP_ENDPOINT_IMAGE_URL}/${data.filename}`;
      }

      // For image, audio and video files we store an reference id to a document in a separate collection.
      set(context.data, path, data); // The response is the id!
    } catch (e) {
      // If something failed, return from the function and don't try to upload any more images.
      return Promise.reject(e);
    }
  }
};

export default processFiles;
