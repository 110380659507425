import { alterItems } from "feathers-hooks-common";
import _ from "lodash";

export default {
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [
      alterItems((rec) => {
        let status = "Mới";

        if (rec.finalResult) {
          status = "Kết quả";
        } else if (rec.entryConfirmed && _.has(rec, "marking")) {
          status = "Chấm chung khảo";
        } else if (rec.entryConfirmed) {
          status = "Chấm sơ khảo";
        }

        _.set(rec, "status", status);
      }),
      (context) => {
        let id = _.get(context, "params.user._id", "none");
        alterItems((rec) => {
          _.set(rec, "markedByMe", _.has(rec, `marking._${id}`));
        })(context);
      },
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
};
