import React from "react";
import { Avatar } from "@material-ui/core";
import { GridShowLayout, RaGrid as Grid } from "ra-compact-ui";
import { FileField, ShowView, ShowController, TextField } from "react-admin";
import _ from "lodash";

const UserTitle = ({ record }) => {
  return (
    <span style={{ display: "flex", flexDirection: "row" }}>
      <Avatar
        src={_.get(record, "avatar.url")}
        style={{ height: "35px", width: "35px", marginRight: "8px" }}
      />
      {record ? `${record.username}` : ""}
    </span>
  );
};

export default (props) => (
  <ShowController {...props}>
    {(controllerProps) => {
      const isContestant = _.get(controllerProps, "record.role", 0) === 0;

      return (
        <ShowView title={<UserTitle />} {...props} {...controllerProps}>
          <GridShowLayout>
            {!isContestant && (
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <TextField source="username" />
                  <TextField source="email" />
                  <TextField source="familyName" />
                  <TextField source="givenName" />
                </Grid>
              </Grid>
            )}
            {isContestant && (
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <TextField source="username" />
                  <TextField source="email" />
                  <TextField source="familyName" />
                  <TextField source="givenName" />
                  <TextField source="name" />
                  <TextField source="profileEmail" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField source="category" />
                  <TextField source="productName" />
                  <TextField source="address" />
                  <TextField source="subCategory" />
                  <TextField source="subject" />
                  <TextField source="idNumber" />
                  <TextField source="vdaAttendee" />
                </Grid>
              </Grid>
            )}
            {isContestant && (
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <FileField
                    source="document1.url"
                    title="document1.name"
                    target="_blank"
                  />
                  <FileField source="document2.url" />
                  <FileField source="document3.url" />
                  <FileField source="document4.url" />
                  <FileField source="document5.url" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FileField source="document6.url" />
                  <FileField source="document7.url" />
                  <FileField source="document8.url" />
                  <FileField source="document9.url" />
                  <FileField source="document10.url" />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FileField source="extraFiles" src="url" title="name" />
                </Grid>
              </Grid>
            )}
          </GridShowLayout>
        </ShowView>
      );
    }}
  </ShowController>
);
