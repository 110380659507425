import React from "react";
import { Admin, Layout, Resource } from "react-admin";
import feathersClient from "./feathersClient";
import { restClient, authClient } from "ra-data-feathers";
import _ from "lodash";

import polyglotI18nProvider from "ra-i18n-polyglot";
import vietnameseMessages from "ra-language-vietnamese";
import domainMessages from "./i18n";

import CogIcon from "@material-ui/icons/Settings";
import PeopleIcon from "@material-ui/icons/People";
import SupervisorIcon from "@material-ui/icons/SupervisorAccount";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";

import Menu from "./Menu";
import UsersList from "./resources/Users/UsersList";
import UsersShow from "./resources/Users/UserShow";
import UsersEdit from "./resources/Users/UsersEdit";
import UsersCreate from "./resources/Users/UsersCreate";
import SiteSettingsEdit from "./resources/SiteSettings/SiteSettingsEdit.js";
import { OrganizationCreate } from "./resources/OrganizationChart/OrganizationCreate";
import { OrganizationList } from "./resources/OrganizationChart/OrganizationList";
import { OrganizationEdit } from "./resources/OrganizationChart/OrganizationEdit";
import { SponsorList } from "./resources/Sponsor/SponsorList";
import { SponsorCreate } from "./resources/Sponsor/SponsorCreate";
import { SponsorEdit } from "./resources/Sponsor/SponsorEdit";
import { CompetitionEdit } from "./resources/Competitions/CompetitionEdit";
import { CompetitionCreate } from "./resources/Competitions/CompetitionCreate";
import { CompetitionList } from "./resources/Competitions/CompetitionList";
import { CategoryList } from "./resources/Categories/CategoryList";
import { CategoryCreate } from "./resources/Categories/CategoryCreate";
import { CategoryEdit } from "./resources/Categories/CategoryEdit";
import { ArticleList } from "./resources/Articles/ArticleList";
import { ArticleCreate } from "./resources/Articles/ArticleCreate";
import { ArticleEdit } from "./resources/Articles/AricleEdit";
import SuperUserList from "./resources/Users/SuperUserList";
import SuperUserEdit from "./resources/Users/SuperUserEdit";
import SuperUserShow from "./resources/Users/SuperUserShow";
import Dashboard from "./dashboard/Dashboard";
//import SuperUserEdit from './resources/SuperUser/SuperUserShow'

const i18nProvider = polyglotI18nProvider(
  () => ({ ...vietnameseMessages, ...domainMessages.vi }),
  "vi"
);

const restClientOptions = {
  id: "_id",
  usePatch: true,
};

const authProvider = authClient(feathersClient, {
  permissionsField: "role",
});

function App() {
  return (
    <Admin
      disableTelemetry
      title="Vietnam Digital Awards"
      i18nProvider={i18nProvider}
      dataProvider={restClient(feathersClient, restClientOptions)}
      authProvider={authProvider}
      layout={(props) => <Layout {...props} menu={Menu} />}
      dashboard={Dashboard}
    >
      {(permissions) => {
        if (!permissions || permissions == 0) {
          // Handle contestant trying to log in to CMS.
          feathersClient.services("authentication").logout();
        }

        const super_ = [5];
        const main = [5, 4];
        const editor = [3];
        const judge = [2, 1];
        const all = _.concat(main, judge, editor);
        const perm = permissions;

        return [
          main.includes(perm) ? (
            <Resource
              name="site-settings"
              icon={CogIcon}
              edit={SiteSettingsEdit}
            />
          ) : null,
          all.includes(perm) ? (
            <Resource
              name="users"
              icon={PeopleIcon}
              list={UsersList}
              show={UsersShow}
              edit={UsersEdit}
            />
          ) : null,
          super_.concat(editor).includes(perm) ? (
            <Resource
              name="superusers"
              icon={SupervisorIcon}
              list={SuperUserList}
              show={permissions >= 4 ? null : SuperUserShow}
              edit={permissions >= 4 ? SuperUserEdit : null}
              create={editor.includes(perm) ? null : UsersCreate}
            />
          ) : null,
          main.includes(perm) ? (
            <Resource
              name="sponsors"
              list={SponsorList}
              create={SponsorCreate}
              edit={SponsorEdit}
            />
          ) : null,
          main.includes(perm) ? (
            <Resource
              name="categories"
              list={CategoryList}
              create={CategoryCreate}
              edit={CategoryEdit}
            />
          ) : null,
          main.includes(perm) ? (
            <Resource
              name="articles"
              list={ArticleList}
              create={ArticleCreate}
              edit={ArticleEdit}
            />
          ) : null,
          main.includes(perm) ? (
            <Resource
              name="organization-chart"
              edit={OrganizationEdit}
              list={OrganizationList}
              create={OrganizationCreate}
            />
          ) : null,
          main.includes(perm) ? (
            <Resource
              name="competitions"
              icon={EmojiEventsIcon}
              edit={CompetitionEdit}
              list={CompetitionList}
              create={CompetitionCreate}
            />
          ) : (
            <Resource name="competitions" />
          ),
          main.includes(perm) ? null : (
            <link rel="stylesheet" type="text/css" href={"/menu.css"} />
          ),
        ];
      }}
    </Admin>
  );
}

export default App;
