import * as React from "react";
import { Create, SimpleForm, NumberInput } from "react-admin";

export const CompetitionCreate = (props) => {
  return (
    <Create {...props} actions={false}>
      <SimpleForm redirect="edit">
        <NumberInput source="year" />
      </SimpleForm>
    </Create>
  );
};
