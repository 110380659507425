import React, { useState } from "react";
import { useInput } from "react-admin";
import { IconPicker } from "react-fa-icon-picker";

const IconInput = (props) => {
  const {
    input: { onChange, value },
  } = useInput(props);

  return <IconPicker value={value} onChange={onChange} />;
};

export default IconInput;
