import React, { useEffect, useState } from "react";
import api from "../feathersClient";

const Dashboard = () => {
  const [year, setYear] = useState(2021);
  const [numEntries, setNumEntries] = useState(0);
  const [numCat1, setNumCat1] = useState(0);
  const [numCat2, setNumCat2] = useState(0);
  const [numCat3, setNumCat3] = useState(0);
  const [numCat4, setNumCat4] = useState(0);

  useEffect(() => {
    const updateData = async () => {
      if (year > 0) {
        try {
          let res;
          res = await api
            .service("users")
            .find({ query: { role: { $lt: 1 } } });
          setNumEntries(res.total);
          res = await api
            .service("users")
            .find({
              query: {
                category: "Sản phẩm, dịch vụ, giải pháp công nghệ số tiêu biểu",
              },
            });
          setNumCat1(res.total);
          res = await api
            .service("users")
            .find({
              query: { category: "Doanh nghiệp chuyển đổi số xuất sắc" },
            });
          setNumCat2(res.total);
          res = await api
            .service("users")
            .find({
              query: { category: "Cơ quan Nhà nước chuyển đổi số xuất sắc" },
            });
          setNumCat3(res.total);
          res = await api
            .service("users")
            .find({
              query: { category: "Giải thưởng về thu hẹp khoảng cách số" },
            });
          setNumCat4(res.total);
        } catch (e) {
          // pass
        }
      }
    };
    updateData();
  }, [year]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "1440px",
      }}
    >
      <h1>Bảng điều khiển</h1>
      <select
        style={{ alignSelf: "flex-end" }}
        defaultValue={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value={0}>Chọn năm</option>
        <option value={2021}>2021</option>
      </select>
      <div
        style={{
          paddingTop: "120px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>Có {numEntries} đơn vị tham gia dự giải</p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>{numCat1}</p>
            <img src={"/cat1.png"} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>{numCat2}</p>
            <img src={"/cat2.png"} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>{numCat3}</p>
            <img src={"/cat3.png"} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>{numCat4}</p>
            <img src={"/cat4.png"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
