import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";
import competitionsHooks from "./services/competitions/competitions.hooks";
import organizationChartHooks from "./services/organization-chart/organization-chart.hooks";
import articleHooks from "./services/articles/articles.hooks";
import userHooks from "./services/users/users.hooks";

const app = feathers();

app.configure(rest(process.env.REACT_APP_API_URL).fetch(window.fetch));
app.configure(
  auth({
    storageKey: "token",
  })
);

app.service("organization-chart").hooks(organizationChartHooks);
app.service("sponsors").hooks(organizationChartHooks);
app.service("articles").hooks(articleHooks);
app.service("users").hooks(userHooks);
app.service("competitions").hooks(competitionsHooks);

export default app;
