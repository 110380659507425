import * as React from "react";
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {
    useListContext,
    CreateButton,
    List, Datagrid, TextField, DateField,useTranslate, ListActions, useRecordContext,ImageField
} from 'react-admin';

const Empty = () => {
    const { basePath, } = useListContext();
    const translate = useTranslate(); // returns the i18nProvider.translate() method
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                {translate('resources.sponsors.fields.noSponsor')}
            </Typography>
            <CreateButton basePath={basePath} />
        </Box>
    );
};

const TranslateTextField = (props) => {
    const translate = useTranslate();
    const { source } = props;
    const record = useRecordContext(props);

    return<Chip label={translate('resources.sponsors.fields.'+record[source])} />;
}

TranslateTextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const useImageFieldStyles = makeStyles(theme => ({
    image: { // This will override the style of the <img> inside the <div>
        maxWidth: 300,
    }
}));

export const SponsorList = (props) => {
    const imageFieldClasses = useImageFieldStyles();

    return (
        <List {...props} actions={<ListActions/>} empty={<Empty/>}>
            <Datagrid rowClick="edit">
                <ImageField classes={imageFieldClasses} source="image" title="image"/>
                <TextField source="name"/>
                <TranslateTextField source="sponsorType"/>
                <DateField source="created_at"/>
            </Datagrid>
        </List>

    );
}
