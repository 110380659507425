import React, { Component } from "react";
import { addField } from "ra-core";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";

import JoditEditor from "jodit-react";
import { joditConfig } from "./joditConfig";
import { SimpleForm } from "../resources/Articles/ArticleCreate";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const styles = {};

class MarkdownInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    source: PropTypes.string,
  };

  state = {
    value: "",
  };

  constructor(props) {
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this)
    const {
      input: { value },
    } = this.props;
    console.log("value", value)
    this.data = value
  }

  componentDidMount() {
    const {
      input: { value },
    } = this.props;

    this.setState({ value });
  }

  handleValueChange(value) {
    this.setState({ value: value });
    this.props.input.onChange(value);
  }

  enableInputTimeout;

  render() {
    return (
      <FormControl fullWidth={true} className="ra-input-mde">
        <SunEditor
            setContents={this.data}
            onChange={this.handleValueChange}
                   setOptions={{
          height: 400,
          buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],

            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
            /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
            ['save', 'template'],],
            defaultStyle: "font-family: Montserrat; font-size: 14px;"
          // plugins: [font] set plugins, all plugins are set by default
          // Other option
        }}/>
      </FormControl>
    );
  }
}

const MarkDownInputWithField = addField(withStyles(styles)(MarkdownInput));

MarkDownInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true,
};
export default MarkDownInputWithField;
