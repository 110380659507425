export default {
  vi: {
    resources: {
      "site-settings": {
        name: "Cấu hình chung",
        fields: {
          name: "Tên",
          number: "Số  diện thoại",
          url: "url",
        },
      },
      superusers: {
        name: "Tài khoản",
        fields: {
          username: "Tên tài khoản",
          familyName: "Họ",
          givenName: "Tên",
          role: "Vai trò",
          examCategory: "Hạng mục chấm thi",
        },
      },
      users: {
        name: "Hồ sơ tham dự",
        fields: {
          isVerified: "Email đã được xác minh",
          year: "Năm",
          username: "Tên tài khoản",
          name: "Họ tên cá nhân/ Tổ chức",
          familyName: "Họ",
          givenName: "Tên",
          profileEmail: "Email thay thế",
          phoneNumber: "SĐT",
          category: "Hạng mục dự thi",
          productName: "Tên sản phẩm",
          address: "Địa chỉ",
          subCategory: "Lĩnh vực dự thi",
          subject: "Đối tượng",
          idNumber: "Mã số thuế/ CMND",
          vdaAttendee: "Người phụ trách tham dự VDA",
          notes: "Ghi chú",
          showOnWebsite: "Hiển thị trên trang web",
          "document1.url": "1. Đơn đăng ký",
          "document2.url": "2. Giấy phép ĐKKD / CMND",
          "document3.url": "3. Báo cáo mô tả sản phẩm dịch vụ",
          "document4.url": "4. Kết quả kinh doanh / ứng dụng sản phẩm dịch vụ",
          "document5.url":
            "5. Các bản sao công chứng đăng ký kinh doanh, giấy phép hoặc tương đương (nếu có)",
          "document6.url":
            "6. Xác nhận của cơ quan thuế, BHXH, BHYT trong 3 năm (nếu có)",
          "document7.url":
            "7. Báo cáo kết quả đánh giá hoạt động môi trường (nếu có)",
          "document8.url":
            "8. Báo cáo kết quả hoạt động ứng dụng KHCN, ứng dụng CNTT (nếu có)",
          "document9.url":
            "9. Các loại chứng nhận, chứng chỉ về chất lượng sản phẩm",
          "document10.url":
            "10. Ý kiến đánh giá, nhận xét của cơ quan quản lý nhà nước địa phương hoặc của Tổ chức/cá nhân đề cử (nếu có)",
          extraFiles: "Tệp bổ sung",
        },
      },
      "organization-chart": {
        name: "Ban tổ chức",
        fields: {
          noOrganization: "Không có ban tổ chức nào",
          title1: "Chức danh 1",
          title2: "Chức danh 2",
          title3: "Chức danh 3",
          userType: "Vị trí",
          chairman: "Chủ Tịch",
          vice_chairman: "Phó Chủ Tịch",
          manager: "Thành viên",
          undefined: "",
        },
      },
      sponsors: {
        name: "Tài trợ",
        fields: {
          commander: "Bảo trợ chỉ đạo",
          executor: "Tổ chức thực hiện",
          sponsor: "Đơn vị tài trợ",
          noSponsor: "Không có dữ liệu",
          sponsorType: "Thuộc đơn vị",
        },
      },
      categories: {
        name: "Chuyên mục",
        fields: {
          name: "Tên chuyên mục",
          note: "Ghi chú",
        },
      },
      articles: {
        name: "Bài viết",
        fields: {
          avatar: "Hình đại điện",
          body: "Nội dung",
          title: "Tiêu đề",
          categories: "Thuộc chuyên mục",
          "createBy.name": "Tác giả",
          isOrder: "Thứ tự",
          created_at: "Ngày tạo",
        },
      },
      competitions: {
        name: "Cuộc thi",
        fields: {
          year: "Năm",
          winnersFile: "Danh sách công ty chiến thắng",
          showResultsOnWebsite: "Hiển thị kết quả trên trang web",
          name: "Tên",
          categories: "Hạng mục dự thi",
          subcategories: "Lĩnh vực dự thi",
          keyDates: "Những ngày quan trọng",
          date: "Ngày",
        },
      },
    },
  },
};
