import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import _ from "lodash";

export const roles = [
  { id: 1, name: "Giám khảo sơ khảo" },
  { id: 2, name: "Giám khảo chung khảo" },
  { id: 3, name: "Biên tập viên" },
  { id: 4, name: "Quản trị viên" },
  { id: 5, name: "Quản lý kỹ thuật" },
];

export default ({ permissions, ...props }) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="familyName" />
        <TextInput source="givenName" />
        <TextInput source="email" />
        <PasswordInput source="password" />
        <SelectInput source="role" choices={roles} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.role === 1 ? (
              <SelectInput
                source="examCategory"
                choices={[
                  {
                    id: 0,
                    name: "Sản phẩm, dịch vụ, giải pháp công nghệ số tiêu biểu",
                  },
                  { id: 1, name: "Doanh nghiệp chuyển đổi số xuất sắc" },
                  { id: 2, name: "Cơ quan Nhà nước chuyển đổi số xuất sắc" },
                  { id: 3, name: "Giải thưởng về thu hẹp khoảng cách số" },
                ]}
                {...rest}
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
