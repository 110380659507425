import processFiles from "../../hooks/processFiles";
import getObjectPaths from "../../util/getObjectPaths";
import { set, map } from "lodash";

const categories = () => (context) => {
  const categories = map(context.result.categories, (item) => item._id);
  set(context.result, "categories", categories);
  return context;
};

export default {
  before: {
    all: [],
    find: [],
    get: [],
    create: [processFiles()],
    update: [processFiles()],
    patch: [processFiles()],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [categories()],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
};
